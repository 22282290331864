<template>
  <div v-if="user">
    <b-row>
        <b-col class="col-12 col-md-3 mt-1 mt-md-0">
            <b-media no-body>
                <b-link>
                <b-img
                    v-if="company.logo"
                    ref="previewEl"
                    rounded
                    :src="company.logo"
                    height="200"
                />
                <!-- <feather-icon
                    v-else
                    icon="CodesandboxIcon"
                    size="200"
                /> -->

                <div v-else style="
                        height:200px;
                        width:200px;
                        border: 2px white dashed;
                        border-radius: 5px;"
                        class="d-flex justify-content-center align-items-center">
                    <span class="align-middle">Company Logo</span>
                </div>
                </b-link>
            </b-media>

            <div class="mt-2">
            <!-- upload button -->
            <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            >
            Upload
            </b-button>
            <b-form-file
            ref="refInputEl"
            v-model="company.logo"
            accept=".jpg, .png, .gif"
            :hidden="true"
            plain
            />
            <!--/ upload button -->

            <!-- reset -->
            <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="mb-75 mr-75"
            >
            Reset
            </b-button>
            <!--/ reset -->
            <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
            </div>
        </b-col>
        <b-col class="col col-12 col-md-9 mt-1 mt-md-0">
            <b-card>
                <!-- User Info: Input Fields -->
                <b-form>
                <b-row>

                    <!-- Field: Name -->
                    <b-col
                    cols="12"
                    md="4"
                    >
                    <b-form-group
                        label="Company Name"
                        label-for="name"
                    >
                        <b-form-input
                        id="name"
                        v-model="company.name"
                        />
                    </b-form-group>
                    </b-col>

                    <!-- Field: Email -->
                    <b-col
                    cols="12"
                    md="4"
                    >
                    <b-form-group
                        label="Admin Email"
                        label-for="email"
                    >
                        <b-form-input
                        id="email"
                        v-model="company.email"
                        type="email"
                        disabled
                        />
                    </b-form-group>
                    </b-col>

                    <b-col
                    cols="12"
                    md="4"
                    v-if="user.role=='admin'"
                    >

                    <b-form-group
                        label="Company Type"
                        label-for="type"
                    >
                        <v-select
                            v-model="companyType"
                            :options="companyTypeOptions"
                            label="title"
                            disabled
                        />
                    </b-form-group>
                    </b-col>

                    <b-col
                    v-if="companyType=='child' && user.role=='admin'"
                    cols="12"
                    md="4"
                    >
                    <b-form-group
                        label="Parent Company"
                        label-for="parent"
                    >
                        <v-select
                            v-model="parentCompany"
                            :options="parentCompanyOptions.rows"
                            label="name"
                            disabled
                        />
                    </b-form-group>
                    </b-col>


                   <b-input-group>
                    <b-input-group-prepend is-text>
                        US (+1)
                    </b-input-group-prepend>
                    <cleave
                        id="phone"
                        v-model="company.phone"
                        class="form-control"
                        :raw="false"
                        :options="options.phone"
                        placeholder="(555) 555-5555"
                    />
                    </b-input-group>

                    <!-- Field: Status -->
                    <!-- <b-col
                    cols="12"
                    md="4"
                    >
                    <b-form-group
                        label="Status"
                        label-for="user-status"
                    >
                        <v-select
                        v-model="userData.status"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="statusOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="user-status"
                        />
                    </b-form-group>
                    </b-col> -->
                </b-row>
                </b-form>
            </b-card>
        </b-col>
    </b-row>

    

    <!-- Action Buttons -->
    <b-row style="flex-direction:column;">
        <b-button
            variant="success"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="updateCompany"
        >
            Update Company
        </b-button>

    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BMediaAside, 
  BMediaBody,
  BLink, 
  BImg,
  BFormFile,
  BCardText,
  BInputGroupPrepend, 
  BInputGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
// import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import AxiosService from '@/Service/AxiosService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    BMediaAside, 
    BMediaBody,
    BLink, 
    BImg,
    BFormFile,
    BCardText,
    BInputGroupPrepend, 
    BInputGroup,
    Cleave
  },
  props: {
  },
  directives: {
    Ripple,
  },
  data(){
    return {
        company:{
            name:"",
            email:"",
            phone:"",
            logo:null,
        },
        profileFile: null,
        refInputEl:null,
        options:{
            phone: {
                numericOnly: true,
                blocks: [0, 3, 3, 4],
                delimiters: ["(", ") ", "-"]
            },
        },
        companyType:null,
        companyTypeOptions:["Parent", "Child"],
        parentCompany:null,
        // parentCompanyOptions:[]
    }
  },
  mounted(){
    this.$store.dispatch("getUser")
    this.getCompanyInfo(this.$route.query.id);
  },
  methods:{
    // inputImageRenderer(){
    //     useInputImageRenderer(refInputEl, base64 => {
    //         // eslint-disable-next-line no-param-reassign
    //         this.avatar = base64
    //     })
    // },
    init(){
        if(!this.user) return;
        if(this.user.role=="admin"){

        }
        else if(this.user.role=="parent"){
            this.companyType = "Child";
            this.parentCompany = this.user.entity._id;
        }
    },
    getCompanyInfo(id){
        AxiosService.get(`/company/${id}`).then(({data, error})=>{
            if(data){
                this.company=data;
                this.companyType = data.type;
                if(data.type=="child") this.parentCompany = data.parent.name;
            }
            else if(error){
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        variant: 'danger',
                        text: error,
                    },
                    })
            }
        })
    },
    clearData(){
        // this.company = {};
        // this.companyType = null;
        // this.parentCompany = null,
        // this.init();
        this.$router.go(-1)
    },
    updateCompany(){
        if(this.company.name && this.company.phone){
            AxiosService.post("/updateCompany", {
                id:    this.company._id,
                name:  this.company.name,
                phone: this.formatNumber(this.company.phone),
                logo:  this.company.logo,
            }).then(({data, error})=>{
                if(data){
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Company Updated`,
                            variant: 'success',
                            text: data,
                        },
                    })
                    this.clearData()
                }else {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Some error occured`,
                            variant: 'danger',
                            text: error,
                        },
                    })
                }
            })
        }
        else {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: `Fields empty`,
                    variant: 'danger',
                    text: 'Please fill all the required fields',
                },
            })
        }
    },
    formatNumber(num){
        let newNum =  num.match(/(\d+)/g)
        return Number(`${newNum[0]}${newNum[1]}${newNum[2]}`)
    }
  },
  watch:{
    companyType(val){
        if(val=="Child"){
            this.$store.dispatch("companies", {type:"parent"})
        }
    },
    user(val){
        if(val) this.init();
    }
  },
  computed: {
    parentCompanyOptions(){
        return this.$store.getters.getCompany || [];
    },
    user(){
        return this.$store.getters.getUser || {};
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>